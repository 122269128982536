<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :form-item-list="formItemList"
      :inline="true"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
    </GlobalForm>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: 'Form',
  data() {
    let _this = this
    return {
      companyId:"",
      isMallEnable:"",
      formItemList: [
        {
          labelName: "门店商家",
          key: "companyId",
          clear: true,
          type: "selectFilterable",
          placeholder: "请选择门店商家",
          option: [],
          clearFun: () => false,
        },
        {
          key: 'isMallEnable',
          type: 'select',
          lableWidth: "155px",
          labelName: '是否启用线上回收业务',
          placeholder: '请选择',
          option:[{
            value:true,
            label:"是",
          },
          {
            value:false,
            label:"否",
          }
          ]
        },
      ],
    }
  },
  created(){
    this.getMerchantList()
    this.handleConfirm()
  },
  methods: {
    // 门店商家下拉
    getMerchantList(){
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          const index = this.formItemList.findIndex(
            (v) => v.key === "companyId"
          );
          this.formItemList[index].option = res.data.map((v) => {
            return {
              label: v.name,
              value: v.id,
            };
          });
        }
      })
    },
    //查询
    handleConfirm(data,cd) {
      if(data){
        this.companyId = data.companyId
        this.isMallEnable = data.isMallEnable
      }else{
        this.companyId = ""
        this.isMallEnable = ""
      }
      const SeachParams = {
        companyId:this.companyId,
        isMallEnable:this.isMallEnable,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      } 
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo{}
</style>
