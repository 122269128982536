<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
    <div slot="AddSlot" >
      <el-button size="small" type="primary" icon="el-icon-plus" @click="$router.push('/classification/add')">添加分类</el-button>
    </div>
    </GlobalForm>
  </div>
</template>

<script>
import {formValidateMobile} from '@/utils/form-validate'
export default {
  name: 'Form',
  data() {
    let _this = this
    return {
      machineTypeName:"",
      enable:"",
      initData: null,
      formItemList: [
        {
          key: 'machineTypeName',
          type: 'input',
          labelName: '分类名称',
          placeholder: '请输入分类名称',
          clear: true,
          clearFun:function () {
            _this.machineTypeName = ""
            const SeachParams = {
              machineTypeName:"",
              pageNum: 1,
              pageSize: 10,
            }
            _this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
          }
        },
        {
          key: 'enable',
          type: 'select',
          labelName: '是否启用',
          placeholder: '请选择启用状态',
          option:[{
            value:"01",
            label:"启用",
          },
          {
            value:"02",
            label:"禁用",
          }
          ]
        },
        {
          key: 'isValidAppleId',
          type: 'select',
          lableWidth: "155px",
          labelName: '是否启用苹果锁验证',
          placeholder: '请选择启用状态',
          option:[{
            value:true,
            label:"启用",
          },
          {
            value:false,
            label:"禁用",
          }
          ]
        },
        {slot:"AddSlot"}
      ],
      formRules: {
        mobile: [{
          validator: formValidateMobile,
          trigger: 'blur'
        }]
      }
    }
  },
  created() {
    this.handleConfirm()
  },
  methods: {
    //查询
    handleConfirm(data,cd) {
      if(data){
        this.machineTypeName = data.machineTypeName
        this.enable = data.enable
        this.isValidAppleId = data.isValidAppleId
      }else{
        this.machineTypeName = ""
        this.enable = ""
      }
      const SeachParams = {
        machineTypeName:this.machineTypeName,
        enable:this.enable,
        isValidAppleId:this.isValidAppleId,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      } 
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
      // this.$emit('handleConfirm',SeachParams)
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo{}
</style>
