<template>
  <div class="class-list">
    <GlobalInfoBar
      title="分类列表"
      content="管理门店店员在报价时，提供回收商品的类型选择，下架分类后将不在小程序端做展示（建立分类前请先创建品牌  前往创建）"
    />
    <div class="button_top">
      <div class="button_top">
        <div class="radiobtn">
          <div class="faultBtn">
              <div v-bind:class="this.clickShow=== '01'? 'left' : 'right'" @click="BtnClick('active')">
                分类管理
              </div>
              <div v-bind:class="this.clickShow=== '02'? 'left2' : 'right'" @click="BtnClick('before')">
                商户分类配置
              </div>
          </div>
        </div>
      </div>
    </div>
    <GlobalChunk icon="search" title="筛选查询" v-if="clickShow == '01'">
      <div slot="filter">
        <ClassListFrom />
      </div>

      <ClassListTable />
    </GlobalChunk>

    <GlobalChunk icon="search" title="筛选查询" v-if="clickShow == '02'">
      <div slot="filter">
        <MerchantForm />
      </div>
      <MerchantTable />
    </GlobalChunk>
  </div>
</template>

<script>
import ClassListFrom from "./From";
import ClassListTable from "./Table";
import MerchantForm from "./components/From";
import MerchantTable from "./components/Table";
export default {
  name: "class-list",
  components: { ClassListFrom, ClassListTable, MerchantForm, MerchantTable },
  data() {
    return {
      clickShow: '01',
    };
  },
  methods: {
    BtnClick(type){
      if(type == 'active' && this.clickShow == '01'){
          return false
      }else{
          this.clickShow = '02'
      }
      if(type == 'before' && this.clickShow == '02'){
          return false
      }else{
          this.clickShow = '01'
      }
   
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.class-list{
  .button_top{
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .faultBtn{
    width: 280px;
    // height: 42px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    .left{
    text-align: center;
    color: white;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 42px;
    height: 42px;
    background: #0981ff;
    border-radius: 20px 0 0 20px;
    }

    .left2 {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 42px;
      height: 42px;
      background: #0981ff;
      border-radius: 0 20px 20px 0;
    }  

    .right{
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 42px;
      height: 42px;
      border-radius: 20px;
    }
  }
}
</style>