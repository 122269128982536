<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columnStyle="columnStyle"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="this.page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="关联采货侠" slot="chxList" align="center">
        <template slot-scope="{ row }">
          <!-- {{row.chxList || '--'}} -->
          <el-tooltip 
            v-if="row.chxList != ''" 
            class="item" 
            effect="dark" 
            :content="row.chxList" 
            placement="top"
          >
            <div class="noteWrap">{{ row.chxList }}</div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="关联分毫" slot="fhList" align="center">
        <template slot-scope="{ row }">
          <!-- {{row.fhList || '--'}} -->
          <el-tooltip 
            v-if="row.fhList != ''" 
            class="item" 
            effect="dark" 
            :content="row.fhList" 
            placement="top"
          >
            <div class="noteWrap">{{ row.fhList }}</div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="未选中图标" slot="operationLogo" align="center">
        <template slot-scope="{ row }">
          <span>
            <img :src="row.logo" width="40" height="40" />
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="选中图标"
        slot="operationChickLogo"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>
            <img :src="row.clickLogo" width="40" height="40" />
          </span>
        </template>
      </el-table-column>
      <el-table-column label="是否启用" slot="operationEnable" align="center">
        <template slot-scope="{ row }">
          <el-switch
            inactive-color="#bebebe"
            @change="handleSwitch(row, 'enable')"
            v-model="row.isEnable"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="线上业务是否启用" slot="isEnableOnline" align="center">
        <template slot-scope="{ row }">
          <el-switch
            inactive-color="#bebebe"
            @change="handleSwitch(row, 'isEnableOnline')"
            v-model="row.isEnableOnline"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        label="是否有苹果系统"
        slot="operationHasIos"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>
            {{ row.isHasIos == "01" ? "是" : "否" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="是否启用苹果锁验证" slot="operationHasIos" align="center">
        <template slot-scope="{ row }">
          <el-switch
            :disabled="row.isEnable"
            inactive-color="#bebebe"
            @change="handleSwitch(row, 'appleId')"
            v-model="row.isValidAppleId"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        label="是否显示规格"
        slot="showSpec"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>
            {{ row.showSpec == "01" ? "是" : "否" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="排序" width="155" slot="operationSort" align="center">
        <template slot-scope="{ row }">
          <el-input-number
            size="mini"
            v-model="row.sort"
            @change="handleChangeNum(row)"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="分类备注" slot="remark" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            v-if="row.remark != ''"
            class="item"
            effect="dark"
            :content="row.remark"
            placement="top"
          >
            <div class="noteWrap">{{ row.remark }}</div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="统一配置到所有门店商户" slot="isConfigToAll" align="center">
        <template slot-scope="{ row }">
          <el-switch
            inactive-color="#bebebe"
            @change="handleSwitch(row, 'isConfigToAll')"
            v-model="row.isConfigToAll"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="报价配置指定品牌管理" slot="assignBrandNum" align="center">
        <template slot-scope="{ row }">
        <div style="display:flex;flex-direction: column;justify-content: center;align-items: center;">
          <span style="color: #0981ff; cursor: pointer;text-decoration:underline;" @click="goBrand(row)">
            {{ row.assignBrandNum }}</span
          >
          <span style="color:#f59a23;cursor: pointer;text-decoration:underline;" @click="goAddBrand(row)">添加指定品牌</span>
        </div>
        </template>
      </el-table-column>
      <el-table-column width="130" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="handleBtn(row, 'edit')" :disabled="row.isEnable"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>

    <!--弹框-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="430px"
      @closed="handleDialogClosed"
    >
      <div style="display:flex;flex-direction: column;align-items: center">
        <span class="dialog-tip">{{dialogTip}}</span>
        <div
          style="
            font-size: 16px;
            color: #666666;
            height: 40px;
            line-height: 50px;
          "
        >
          <div v-if="switchType == 'appleId'">确认要执行该操作吗？</div>
          <div v-else-if="switchType == 'enable'">是否确认{{switchFlag ? '启用' :'禁用'}}<span style="color:#0981ff">{{name}}</span>分类</div>  
          <div v-else-if="switchType == 'isEnableOnline'">是否确认在线上回收业务{{switchFlag ? '启用' :'禁用'}}<span style="color:#0981ff">{{name}}</span>分类</div>  
          <div v-else-if="switchType == 'isConfigToAll'">是否确认将<span style="color:#0981ff">{{name}}</span>统一{{switchFlag ? '配置' :'关闭'}}给所有门店商户</div>  
        </div>
        <div class="command">
          <div style="width: 85px;">动态口令：</div>
          <el-input
            v-model="command"
            placeholder="请输入谷歌动态口令"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click.native="handleSuccess"
          >确定</el-button
        >
        <el-button @click.native="handleClose">取消</el-button>
      </span>
    </el-dialog>
    <!-- 添加品牌弹框 -->
    <el-dialog
      title="添加品牌"
      :visible.sync="dialogBrandVisible"
      :close-on-click-modal="false"
      width="530px"
      @closed="handleClosed"
    >
      <div style="display:flex;flex-direction: column;">
        <span class="dialog-tip">说明：新增分类默认为其他品牌，可添加某个品牌作为指定品牌进行报价配置，添加后将同步更新回收商的报价配置</span>
        <el-form ref="form" :model="modelform" :rules="modelrules" style="margin-top:10px;">
          <el-form-item label="选择品牌" size="medium" label-width="100px" prop="brandId">
            <el-select v-model="modelform.brandId" clearable filterable
              placeholder="请选择需要独立配置的报价品牌" @change="handleChange(item)">
              <el-option v-for="o in brandList" :key="o.id" :label="o.name" :value="o.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="是否统一配置给所有回收商参与报价" label-width="135px">
            <el-radio-group v-model="modelform.isAllConfig">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="动态口令" size="medium" label-width="100px" prop="command">
            <el-input
              v-model="modelform.command"
              placeholder="请输入谷歌动态验证码"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleBrandClosed">取消</el-button>
        <el-button type="primary" @click.native="handleBrandSuccess">确定</el-button>
      </span>
    </el-dialog>
    <!-- 品牌列表 -->
    <el-dialog
      title="品牌列表"
      :visible.sync="brandListVisible"
      :close-on-click-modal="false"
      width="400px"
    >
      <GlobalTable
        ref="BrandTable"
        :columns="brandColumns"
        :data="brandDataList"
        :maxHeight="700"
        :isPagination="false"
      >
        <template slot>
          <div>已设置10个指定品牌，剩余品牌均归类为其他 </div>
        </template>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click.native="handleSuccess">确定</el-button> -->
        <el-button type="primary" @click.native="brandListVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
export default {
  name: "Table",
  mixins: [MinXinRequest],
  data() {
    return {
      // brandId:'',
      brandList:[],
      // isAllConfig:true,
      command:'',
      brandColumns:[{
        label:'指定品牌',prop:'name'
      }],
      brandDataList:[],
      seachDataList: [],
      ResidentEnable: {
        id: "",
        isEnable: "",
        command:"",
      },
      pageNum: 1,
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      dialogBrandVisible:false,//品牌弹框
      brandListVisible:false,//品牌列表弹框
      dialogTitle: "", // 标题
      dialogTip: "", // 弹窗提示内容
      tableColumns: [
        { label: "分类编码", prop: "type" },// 分类编码
        { label: "分类名称", prop: "name" },
        { slotName: "chxList" },// 关联采货侠
        { slotName: "fhList" },// 关联分毫
        { slotName: "operationLogo" },
        { slotName: "operationChickLogo" },
        { slotName: "operationEnable" },
        { slotName: "isEnableOnline" },//线上业务是否启用
        { slotName: "operationHasIos" },
        { slotName: "showSpec" },
        { slotName: "operationSort" },
        { slotName: "remark" },// 分类备注
        { slotName: "isConfigToAll" },//统一配置到所有门店商户
        { slotName: "assignBrandNum" },//报价配置指定品牌管理
        { label: "最后更新时间", prop: "updateTime" },
        { slotName: "operation" },
      ],
      //   currentRow: {}
      switchType:'',
      switchFlag:false,
      name:'',
      modelform:{
        brandId:"",
        command:"",
        isAllConfig:true,
      },
      modelrules:{
        brandId : [
          { required: true, message: "请选择品牌", trigger: "blur" },
        ],
        command: [
          { required: true, message: "请输入谷歌动态验证码", trigger: "blur" },
        ],
      }
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    seachTotal() {
      return this.$store.state.tagsView.seachTotal;
    },
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.pageNum = 1
      this.handleCurrentChange();
    },
  },
  methods: {
    // 选择品牌下拉
    goBrand(row){
      console.log('品牌列表',row);
      const param = {
        id:row.id,
        isAssign:true
      }
      _api.selectAssignBrandList(param).then(res => {
        if (res.code === 1) {
          this.brandDataList = res.data
        }
      })
      this.brandListVisible = true
    },
    goAddBrand(row){
      this.machineTypeId = row.id
      const param = {
        id:row.id,
        isAssign:false
      }
      _api.selectAssignBrandList(param).then(res => {
        if (res.code === 1) {
          this.brandList = res.data
        }
      })  
      this.dialogBrandVisible = true
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        machineTypeName: this.SeachParams.machineTypeName,
        enable: this.SeachParams.enable,
        isValidAppleId: this.SeachParams.isValidAppleId,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.$emit("handleCurrentChange", val);
      _api.classList(baseRequest).then((res) => {
        if (res.code === 1) {
          const seachDataList = res.data.records;
          this.page.pageNum = res.data.current;
          this.page.total = res.data.total;
          this.$store.commit("tagsView/POST_TOTAL", res.data.total);
          this.$store.commit("tagsView/POST_RESIDENT", this.seachDataList);
          for (const key in seachDataList) {
            // console.log('大列巴2',seachDataList[key]['fhList'].map(el => el.name).join(','));
            // 采货侠,分毫数据处理
            seachDataList[key]['chxList'] = seachDataList[key]['chxList'].map(el => el.name).join('，')
            seachDataList[key]['fhList'] = seachDataList[key]['fhList'].map(el => el.name).join('，')
          }
          this.seachDataList = seachDataList
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 表格按钮操作
    handleBtn(row, type) {
      console.log("表格操作", type, row);
      this.$store.commit("tagsView/POST_RESIDENT_FORMDATA", row);
      this.$router.push({
        path: "/classification/add",
        query: { type: "edit", id: row.id },
      });
    },
    //弹窗
    handleSwitch(row,type) {
      console.log('开关',row,type);
      if(type == 'appleId'){
        this.switchType = type
        let dialogTitle = row.isValidAppleId ? "启用苹果锁验证" : "关闭苹果锁验证";
        let dialogTip = row.isValidAppleId ? "提示：启用后该分类下苹果旧机可使用苹果锁进行验证" : "提示：关闭后该分类下苹果旧机将不再使用苹果锁验证";
        this.dialogTitle = dialogTitle;
        this.dialogTip = dialogTip;
        this.dialogVisible = true;
        this.ResidentEnable.id = row.id;
        this.ResidentEnable.isValidAppleId = row.isValidAppleId;
        this.ResidentEnable.type = type
      }else if(type == 'enable'){
        this.name = row.name
        this.switchType = type
        this.switchFlag = row.isEnable
        let dialogTitle = row.isEnable ? "启用分类" : "禁用分类";
        // let dialogTip = row.isEnable ? "提示: 启用后该分类将在门店小程序端-首页报价页面显示" : "提示: 禁用后该分类将不在门店小程序端-首页报价页面显示";
        let dialogTip = row.isEnable ? "说明: 启用后该分类将显示在门店端小程序-下单页面、3秒估价页面、添加二手优品页面" : "说明: 禁用后该分类在门店端小程序-下单页面、3秒估价页面、添加二手优品页面等页面将不再显示，用户无法选择该分类开展业务";
        this.dialogTitle = dialogTitle;
        this.dialogTip = dialogTip;
        this.dialogVisible = true;
        this.ResidentEnable.id = row.id;
        this.ResidentEnable.isEnable = row.isEnable;
        this.ResidentEnable.type = type
      }else if(type == 'isEnableOnline'){
        this.name = row.name
        this.switchType = type
        this.switchFlag = row.isEnableOnline
        let dialogTitle = row.isEnableOnline ? "启用线上业务" : "禁用线上业务";
        let dialogTip = row.isEnableOnline ? "说明: 启用后该分类将显示线上回收小程序-估价页面，仅支持启用关联了分毫的分类" : "说明: 禁用后该分类在线上回收小程序-估价页面不再显示，用户无法选择该分类估价";
        this.dialogTitle = dialogTitle;
        this.dialogTip = dialogTip;
        this.dialogVisible = true;
        this.ResidentEnable.id = row.id;
        this.ResidentEnable.isEnableOnline = row.isEnableOnline;
        this.ResidentEnable.type = type
      }else if(type == 'isConfigToAll'){
        this.name = row.name
        this.switchType = type
        this.switchFlag = row.isConfigToAll
        let dialogTip = row.isConfigToAll ? "说明: 是否确认统一配置给所有商户，确认后将立即生效，商户下单将新增该分类" : "说明: 是否确认统一关闭配置给所有商户，确认后将立即生效，商户下单将不可在选择该分类";
        this.dialogTitle = "操作提示";
        this.dialogTip = dialogTip;
        this.dialogVisible = true;
        this.ResidentEnable.id = row.id;
        this.ResidentEnable.isConfig = row.isConfigToAll;
        this.ResidentEnable.type = type
      }
      
    },
    // //弹窗取消
    handleClose() {
      this.dialogVisible = false;
    },
    //弹窗确定
    handleSuccess() {
      this.ResidentEnable.command = this.command
      if(this.ResidentEnable.type == 'appleId'){
        _api.openAppleIdValid(this.ResidentEnable).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.dialogVisible = false;
          }
        });
      }else if(this.ResidentEnable.type == 'enable'){
        _api.classEnable(this.ResidentEnable).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.dialogVisible = false;
          }
        });
      }else if(this.ResidentEnable.type == 'isEnableOnline'){
        _api.updateIsEnableOnline(this.ResidentEnable).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.dialogVisible = false;
          }
        });
      }else if(this.ResidentEnable.type == 'isConfigToAll'){
        _api.configToAll(this.ResidentEnable).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.dialogVisible = false;
          }
        });
      }
    },
    handleClosed() {
     this.$refs["form"].resetFields();
      this.modelform.brandId = ""
      this.modelform.isAllConfig = true
      this.modelform.command = ""
    },
    handleBrandClosed(){
      this.handleClosed()
      this.dialogBrandVisible = false
    },
    // 添加品牌
    handleBrandSuccess(){
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const params = {
            brandId:this.modelform.brandId,
            command:this.modelform.command,
            isConfigToAll:this.modelform.isAllConfig,
            machineTypeId:this.machineTypeId
          }
          _api.assignBrand(params).then(res => {
            if (res.code === 1) {
              this.$message.success(res.msg);
              this.dialogBrandVisible = false
            }
          })
        } else {
          console.log('请选择品牌并输入动态口令！');
          // this.$message.error('请选择品牌并输入动态口令！');
        }
      });
      this.handleCurrentChange()
    },
    handleDialogClosed() {
      this.command = ""
      this.dialogVisible = false;
      this.handleCurrentChange();
    },
    //排序
    handleChangeNum(val) {
      const params = {
        id: val.id,
        sort: val.sort,
      };
      _api.classSort(params).then((res) => {
        if (res.code === 1) {
          this.handleCurrentChange(this.pageNum);
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    columnStyle({ row, column, rowIndex, columnIndex }) {
      // console.log(row, column, rowIndex);
      if (columnIndex === 3) {
        //第三第四列的背景色就改变了2和3都是列数的下标
        return "background: #F5F6FC";
      }
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .dialog-tip{
    font-size: 12px;
    color: #FF687B;
  }
  .noteWrap{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .command {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    /deep/ .el-input {
      width: 300px;
    }
  }
  /deep/.el-select > .el-input {
    display: block;
    width: 390px;
}
}
</style>
