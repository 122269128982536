<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
    
    <el-table-column label="最近修改人" slot="updateMachineTypeName" align="center">
      <template slot-scope="{ row }">
        <span>{{row.updateMachineTypeName}}</span>
        <span v-if="row.updateMachineTypeAccount != ''"> - {{row.updateMachineTypeAccount}}</span>
      </template>
    </el-table-column>
    <el-table-column
        label="是否启用线上回收业务"
        slot="isMallEnable"
        align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="row.isMallEnable != null">
            {{ row.isMallEnable ? "是" : "否" }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
    
      <!-- 
        <template slot-scope="{ row }">
          {{row.fhList}}
        </template>
      </el-table-column>
      <el-table-column label="未选中图标" slot="operationLogo" align="center">
        <template slot-scope="{ row }">
          <span>
            <img :src="row.logo" width="40" height="40" />
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="选中图标"
        slot="operationChickLogo"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>
            <img :src="row.clickLogo" width="40" height="40" />
          </span>
        </template>
      </el-table-column>
     
      <el-table-column
        label="是否有苹果系统"
        slot="operationHasIos"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>
            {{ row.isHasIos == "01" ? "是" : "否" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="是否启用苹果锁验证" slot="operationHasIos" align="center">
        <template slot-scope="{ row }">
          <el-switch
            :disabled="row.isEnable"
            inactive-color="#bebebe"
            @change="handleSwitch(row, 'appleId')"
            v-model="row.isValidAppleId"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="统一配置到所有门店商户" slot="isConfigToAll" align="center">
        <template slot-scope="{ row }">
          <el-switch
            inactive-color="#bebebe"
            @change="handleSwitch(row, 'isStore')"
            v-model="row.isConfigToAll"
          ></el-switch>
        </template>
      </el-table-column> -->
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="handleBtn(row, 'config')">分类配置</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 分类配置 -->
    <el-dialog
      :title="configTitle"
      :visible.sync="classConfigVisible"
      :close-on-click-modal="false"
      width="800px"
      @closed="handleDialogClosed"
    >
      <div style="display:flex;justify-content: end;margin-bottom: 10px;">
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="handleAdd">添加分类</el-button>
      </div>
      <GlobalTable
        ref="classTable"
        v-loading="classLoading"
        :columns="classConfigColumns"
        :data="classConfigData"
        :currentPage="classPage.pageNum"
        :total="classPage.total"
        :isPagination =false
        @handleCurrentChange="handleSubmit"
        >
        <el-table-column label="平台启用状态" slot="platformIsEnable" align="center">
          <template slot-scope="{ row }">
            <span :style="{color:row.platformIsEnable ? '#0981ff': 'red'}">{{ row.platformIsEnable ? '已启用':'已禁用' }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="onlineFlag" label="平台线上回收启用状态" slot="platformIsEnableOnline" align="center">
          <template slot-scope="{ row }">
            <span :style="{color:row.platformIsEnableOnline ? '#0981ff': 'red'}">{{ row.platformIsEnableOnline ? '已启用':'已禁用' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否启用" slot="isEnable" align="center">
          <template slot-scope="{ row }">
            <el-switch
              inactive-color="#bebebe"
              v-model="row.isEnable"
              :disabled="!row.platformIsEnable"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column v-if="onlineFlag" label="线上回收是否启用" slot="isEnableOnline" align="center">
          <template slot-scope="{ row }">
            <el-switch
              inactive-color="#bebebe"
              v-model="row.isEnableOnline"
              :disabled="!row.platformIsEnableOnline"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column  width="155" label="分类排序" slot="operationSort" align="center">
          <template slot-scope="{ row }">
            <el-input-number
              size="small"
              v-model="row.sort"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="caozuo" align="center">
          <template slot-scope="{ row }">
            <el-button size="mini" type="primary" round @click="goDetails(row)"
            >订单详情
            </el-button
            >
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="classConfigVisible = false">取消</el-button>
        <el-button type="primary" @click.native="operateVisible = true">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="操作提示"
      :visible.sync="operateVisible"
      :close-on-click-modal="false"
      width="530px"
      @closed="handleDialogClosed"
    >
      <div style="display:flex;flex-direction: column;">
        <span class="dialog-tip" style="height:40px;line-height:40px;">说明：请勿轻易启用或禁用分类，启用分类后重评订单将会报错</span>
        <span style="text-align:center;height:40px;line-height:40px;">是否确认修改该商户的分类配置？</span>
        <el-form style="margin-top:10px;">
          <el-form-item label="动态口令" size="medium" label-width="100px">
            <el-input
              v-model="command"
              placeholder="请输入谷歌动态验证码"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="handleClose">取消</el-button>
        <el-button type="primary" @click.native="handleSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 添加分类 -->
    <el-dialog
      title="添加分类"
      :visible.sync="classAddVisible"
      :close-on-click-modal="false"
      width="800px"
    >
      <el-table
        ref="multipleTable"
        :data="classAddData"
        border
        tooltip-effect="dark"
        style="width: 100%"
        max-height="590px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="70"
          :index="(index) => index + 1"
        />
        <el-table-column prop="name" align="center" label="分类名称"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="classAddVisible = false">取消</el-button>
        <el-button type="primary" @click.native="onAdd">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
export default {
  name: "Table",
  mixins: [MinXinRequest],
  data() {
    return {
      classAddData:[],
      operateVisible:false,
      classLoading:false,
      classConfigColumns:[
        { label: "分类名称", prop: "name" },
        { slotName: "platformIsEnable" },//平台启用状态
        { slotName: "platformIsEnableOnline" },//平台线上回收启用状态
        { slotName: "isEnable" },//是否启用
        { slotName: "isEnableOnline" },//线上回收是否启用  
        { slotName: "operationSort" },//分类排序  
      ],
      classConfigData:[],
      mediumData:[],
      classPage:{
        pageNum:1,
        total:0
      },
      command:'',
      seachDataList: [],
      ResidentEnable: {
        id: "",
        isEnable: "",
      },
      pageNum: 1,
      //弹窗
      classConfigVisible:false,//品牌弹框
      classAddVisible:false,//品牌列表弹框
      dialogTitle: "", // 标题
      dialogTip: "", // 弹窗提示内容
      tableColumns: [
        { label: "商户ID", prop: "companyId" },
        { label: "门店商户", prop: "companyName" },
        { label: "联系人", prop: "contact" },
        { slotName: "isMallEnable" },
        { label: "已启用分类", prop: "machineTypeNames" },
        { slotName: "updateMachineTypeName" },//最近修改人
        { label: "最后修改时间", prop: "updateMachineTypeTime" },
        { slotName: "operation" },
      ],
      switchType:'',
      switchFlag:false,
      name:'',
      onlineFlag:true,
      companyId:'',
      filterMachineTypeIds:[],
      configTitle:''
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.pageNum = 1
      this.handleCurrentChange();
    },
  },
  methods: {
    // 添加分类提交按钮
    onAdd(){
      this.classConfigData = [...new Set(this.mediumData.concat(this.selectData))] 
      this.classAddVisible = false
    },
    // 添加分类选择
    handleSelectionChange(val) {
      this.selectData = val
      console.log('选中',this.selectData);
    },
    // 添加分类列表获取
    handleAdd(){
      this.classAddVisible = true
    },
    // 分类配置-提交按钮
    handleSubmit(){
      const arr = this.classConfigData.map(el => {
        return {
          id:el.id,
          isEnable:el.isEnable,
          isEnableOnline:el.isEnableOnline,
          sort:el.sort,
        }
      })
      const param = {
        command: this.command,
        companyId: this.companyId,
        typeList: arr
      }
      _api.saveCompanyMachineType(param).then((res) => {
        if (res.code === 1) {
          this.$message.success(res.msg);
          this.operateVisible = false
          this.classConfigVisible = false
        }
      });
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        companyId: this.SeachParams.companyId,
        isMallEnable: this.SeachParams.isMallEnable,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      _api.classCompanyListt(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.pageNum = res.data.current;
          this.page.total = res.data.total;
          this.$store.commit("tagsView/POST_TOTAL", res.data.total);
          this.$store.commit("tagsView/POST_RESIDENT", this.seachDataList);
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 表格按钮操作
    async handleBtn(row, type) {
      console.log("表格操作", type, row);
      this.configTitle = '分类配置-' + row.companyName
      this.onlineFlag = row.isMallEnable
      this.companyId = row.companyId
      if(type == 'config'){
        await _api.companyMachineType({companyId:row.companyId,type:'01'}).then((res) => {
          if (res.code === 1) {
            this.classConfigData = res.data;
            this.filterMachineTypeIds = res.data.map(el => el.id);
            console.log('数组数组',this.filterMachineTypeIds);
            this.mediumData = res.data
          }
        });
        this.classConfigVisible = true;
        this.classList()
      }
    },
    classList(){
      const params = {
        type:'02',
        filterMachineTypeIds:this.filterMachineTypeIds, 
        companyId:this.companyId,
      }
      _api.companyMachineType(params).then((res) => {
        if (res.code === 1) {
          this.classAddData = res.data;
        }
      });
    },
    // 弹窗关闭
    handleClose() {
      this.operateVisible = false;
    },
    handleDialogClosed() {
      this.handleCurrentChange();
    },
    //排序
    // handleChangeNum(val) {
    //   const params = {
    //     id: val.id,
    //     sort: val.sort,
    //   };
    //   _api.classSort(params).then((res) => {
    //     if (res.code === 1) {
    //       this.handleCurrentChange(this.pageNum);
    //       this.$message.success(res.msg);
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .dialog-tip{
    font-size: 12px;
    color: #FF687B;
  }
}
</style>
